import React, { useEffect, useState, useCallback, useRef } from 'react';
import "../Styles/AdsView.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Input, Label } from 'reactstrap';
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete from "react-google-places-autocomplete"
import { geocodeByPlaceId } from "react-google-places-autocomplete"

const libraries = ["places"];

const CustomeMap = ({ Latitude, Longitude, onChange, setLoaction }) => {


    const [selectedPlacesObject, setSelectedPlacesObject] = useState(null)

    const [selectedLocation, setSelectedLocation] = useState({ Latitude: Latitude, Longitude: Longitude });
    const center = {
        lat: Latitude,
        lng: Longitude,

    };

    const mapRef = useRef(null);
    // Definiere die Karteinstellungen
    const mapContainerStyle = {
        width: "100%",
        height: "300px",
    };


    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDQ_2e2I1x9wphBEO_bACNQsNTuUKeYfng", // Füge hier deinen API-Key ein
        libraries,
    });
    // Definiere die Bibliotheken für die Map


    const options = {
        disableDefaultUI: true,
        zoomControl: true,
    };
    useEffect(() => {
        setSelectedLocation({ Latitude: Latitude, Longitude: Longitude })

    }, [Latitude, Longitude])

    useEffect(() => {
        if (mapRef.current)
            mapRef.current.panTo({ lat: selectedLocation.Latitude, lng: selectedLocation.Longitude });
        onChange(selectedLocation.Latitude, selectedLocation.Longitude)
    }, [selectedLocation])

    // Handler für den Klick auf die Karte
    const onMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setSelectedLocation({ Latitude: lat, Longitude: lng });
        getPlaceByLatLng(lat, lng)
        console.log("Latitude:", lat, "Longitude:", lng);
    }, []);

    function decodePlaceId(value) {
        geocodeByPlaceId(value.value.place_id)
            .then(result => {
                const location = result[0].geometry.location;
                const selectedLocation = { Latitude: parseFloat(location.lat()), Longitude: parseFloat(location.lng()) }
                setSelectedLocation(selectedLocation)
            })
    }

    const handlePlaceChanged = (place) => {
        setSelectedPlacesObject(place)
        decodePlaceId(place)
    }
    const createPlacesObject = (place) => {
        return {
            label: place.formatted_address,
            value: {
                description: place.formatted_address,
                place_id: place.place_id,
                location: {
                    lat: place.lat,
                    lng: place.lng,
                },
            },
        };
    }
    const getPlaceByLatLng = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };

        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    setSelectedPlacesObject(createPlacesObject(results[0])); // Speichert die Adresse im State
                } else {
                    console.log("Keine Ergebnisse gefunden.");
                }
            } else {
                console.error("Geocoding war nicht erfolgreich, Grund: " + status);
            }
        });
    }

    //#region  Auth0
    const {
        user,
        getAccessTokenSilently,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();

    //#region Use Effects

    return (
        <div>
            {isLoaded &&
                <div>
                    <div style={{ marginBottom: "20px" }}>
                        <PlacesAutocomplete
                            selectProps={{
                                value: selectedPlacesObject,
                                onChange: handlePlaceChanged
                            }}
                            apiOptions={{ language: "de", region: "de" }}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country: ["de"],
                                }
                            }}
                        />
                    </div>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={10}
                        center={{ lat: selectedLocation.Latitude, lng: selectedLocation.Longitude }}
                        options={options}
                        onClick={onMapClick}
                        style={{ marginTop: "20px" }}

                        onLoad={(map) => {
                            mapRef.current = map
                            getPlaceByLatLng(selectedLocation.Latitude, selectedLocation.Longitude)
                        }}
                    >
                        <Marker position={{ lat: selectedLocation.Latitude, lng: selectedLocation.Longitude }} />
                    </GoogleMap>
                    <div className='twoRowContainer' style={{ marginTop: "20px" }}>
                        <div className='GeoInput'>
                            <Label>Lat</Label>
                            <Input className='defaultInput' value={selectedLocation.Latitude}
                                onChange={(event) => {
                                    setSelectedLocation(
                                        { Latitude: parseFloat(event.target.value), Longitude: selectedLocation.Longitude }
                                    )
                                }}> </Input>
                        </div>
                        <div className='GeoInput'>
                            <Label>Lng</Label>
                            <Input className='defaultInput' value={selectedLocation.Longitude}
                                onChange={(event) => {
                                    setSelectedLocation(
                                        { Latitude: selectedLocation.Latitude, Longitude: parseFloat(event.target.value) }
                                    )
                                }} />

                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default CustomeMap;