import React, { Fragment } from "react";

import AdsHome from "../components/AdsHome";

const Home = () => (
  <Fragment>
    <AdsHome />
    <hr />
  </Fragment>
);

export default Home;
