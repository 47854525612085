import React, { Component, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, TabNav, TabNavItem, TabContent, TabPanel } from 'react-smarttab'
import CreateLocationComponent from './LocationComponents/Location.js'
import Create_Event from './LocationComponents/Create_Event.js'
import { useAuth0 } from "@auth0/auth0-react";
import {useHistory} from "react-router-dom";
import '../App.css'
import 'react-smarttab/dist/index.css'

const New = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const history  = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/")
    }
  })

  return (
    <div className="tab_panel_container" style={{width:"100%"}}>
      {isAuthenticated && (
        <Tabs tabName="tab1" selected={selectedTab}
          onShowTab={(currentIndex) => { setSelectedTab(currentIndex); console.log("index", selectedTab) }}>
          <TabNav>
            <TabNavItem>Ort erstellen</TabNavItem>
          </TabNav>
          <TabContent>
            <TabPanel>
              <CreateLocationComponent/>
            </TabPanel>
            <TabPanel>
              <Create_Event />
            </TabPanel>
          </TabContent>
        </Tabs>
      )}
    </div>
  );

}

export default New;
