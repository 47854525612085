import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardImg, CardHeader } from "reactstrap";
import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getRoles } from "@testing-library/react";
import "../App.css"



export const ProfileComponent = () => {
  const { user } = useAuth0();

  function getRoles(){
    let ret ="";
    user["https://familyactivies.de/roles"].forEach(element => {
      ret = ret+element+" & ";
    });
    return ret.slice(0,-2)
  }

  return (
    <div className="center">
      <img
        src={user.picture}
        alt="Profile"
        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
      />
      <Card style={{ width: "400px", height: "400px", marginTop:"25px" }}>
        <CardHeader style={{fontSize:"20px"}}><b>{user.nickname}</b></CardHeader>
        <CardBody>
          <p><b>E-Mail:</b> {user.email}</p>
          <p><b>E-Mail verifiziert:</b> {user.email_verified ? "Ja":"Nein"}</p>
          <p><b>Bearbeitet am: </b>{new Date(user.updated_at).toLocaleDateString("de-DE")}</p>
          <p><b>Rollen:<br></br></b>{getRoles()}</p>
          <p><b>Subscription ID:</b> {user.sub}</p>
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
