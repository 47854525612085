import properties from "highlight.js/lib/languages/properties";
import React from "react";
import loading from "../assets/loading.svg";

const Loading = (props) => (
  <div className={props.hidden ? "spinner_hidden" : "spinner"}>
    <img src={loading} alt="Loading" />
  </div>
);

export default Loading;
