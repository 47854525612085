import React from 'react';
import "../Styles/ImageModal.css"
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="image_modal-overlay" onClick={onClose}>
      <div className="image_modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={imageUrl} alt="Full size" />
        <button className="image_close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faCircleXmark} style={{color: 'lightgray'}} />
        </button>
      </div>
    </div>
  );
};

export default ImageModal;