import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-smarttab/dist/index.css'


const Create_Event = () => {
  return (
    <div>
      Hier kannst du ein Event erstellen
    </div>
  );
}

export default Create_Event;
