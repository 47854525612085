import React, { useState } from 'react';
import "../Styles/AdvertieserModal.css"

const CampaignModal = ({ isOpen, onClose, onSubmit }) => {
    const [title, setTitle] = useState('Default');

    if (!isOpen) {
        return null; // Wenn das Modal nicht geöffnet ist, rendere nichts
    }

    const handleSubmit = (e) => {
        onSubmit(title);
    };

    return (
        <div className="adv-modal-overlay">
            <div className="adv-modal">
                <h2>Enter Details</h2>
                <div className="input-group">
                    <label htmlFor="name">Title:</label>
                    <input
                        type="text"
                        id="name"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                        className='defaultModalInput'
                    />
                </div>
                <div >
                    <button type="submit" className="actionButton"
                        style={{ width: "100px", marginRight: "20px", fontWeight: 600 }}
                        onClick={() => { onSubmit(title) }}>Create</button>
                    <button type="button" className="actionButton"
                        style={{ width: "100px", fontWeight: 600 }} onClick={onClose} >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CampaignModal;
