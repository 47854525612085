function isUserAuthorized(user) {
    if (!user)
        return false;
    let ret = false;
    const knownRoles = ["Admin", "ContentCreator","ContentManager"]
    const userRoles = user["https://familyactivies.de/roles"]
    knownRoles.forEach(element => {
        if (userRoles.includes(element))
            ret = true
    });
    return ret;
}

function isUserContentManager(user) {
    if (!user)
        return false;

    const userRoles = user["https://familyactivies.de/roles"]
    return userRoles.includes("ContentManager")
}

function isUserAdmin(user) {
    if (!user)
        return false;

    const userRoles = user["https://familyactivies.de/roles"]
    return userRoles.includes("Admin")
}

module.exports = { isUserAdmin, isUserAuthorized,isUserContentManager }