
import React, { useState }  from 'react';
import "../Styles/ImageContainer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ImageModal from './ImageModal';
import imagePlaceholder from "../../assets/Image_Placeholer.png"

const ImageContainer = ({ id, imageUrl,onTrashbinClicked}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleImageClick = (url) => {
        setIsModalOpen(true);
        console.log("Open Modal")
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='container'>
            <img src={imageUrl} alt="Image" className='image' onClick={handleImageClick} />
            <div className='circle'>
                { imageUrl != imagePlaceholder && 
                <div className='trashbin'>
                    <FontAwesomeIcon icon={faTrash} onClick={() => onTrashbinClicked(imageUrl,id)} />
                </div>
                }
            </div>
            {isModalOpen && <ImageModal imageUrl={imageUrl} onClose={handleCloseModal} />}
        </div>
    );
};

export default ImageContainer;