import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import './Styles/Landing.css'
import { isUserAuthorized } from "../userAuth";

/**
 * Temporary workaround
 */

import { useAuth0 } from "@auth0/auth0-react";
import Location from "./LocationComponents/Location";
import { render } from "react-dom";
//const mock_login=process.env.REACT_APP_MOCK_LOGIN == true;

const Hero = () => {

  const [accessToken, setAccessToken] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const baseUrl = process.env.REACT_APP_SERVER
  let activeId = 0;
  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  function logoutUser() {
    alert("Sie sind nicht berechtigt diese Seite zu benutzen. Bitte wennden sie sich an einen Adminestrator oder schreiben sie eine E-Mail an tobias.franz@posteo.de um Zugriff zu beantragen.")
    logoutWithRedirect();
  }

  useEffect(() => {
    if (isAuthenticated) {
      console.log('user is authorized')
      getAccessTokenSilently().then(success => {
        setAccessToken(success);
        fetch(baseUrl + '/feedback', {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': baseUrl,
            'Authorization': 'Bearer ' + success,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            }
            logoutWithRedirect();
          })
          .then(data => {
            setFeedback(data.reverse())
            console.log('data', data);
          }).catch(e => {
            console.log('catch ', e);
          })

      }).catch(error => console.log('err', error))
    }
  }, [isAuthenticated])

  function displayFeedback() {
    return feedback.map((feedback, index) => {
      let date = new Date(feedback.createdAt)
      return (
        <div>
          <Card>
            <CardBody>
              <CardTitle>{feedback.email}</CardTitle>
              <CardSubtitle>{date.toLocaleDateString("de-DE")}</CardSubtitle>
              <hr/>
              {feedback.message}
            </CardBody>
          </Card>
          <br />
        </div>
      )

    })

  }

  return (
    <div>
      {isAuthenticated && isUserAuthorized(user) && (
        <div>
          <h1> Feedback</h1>
          <hr />
          <div id="Feedback Container"></div>
          {
            displayFeedback()
          }
        </div>
      )}
    </div >
  )
};

export default Hero;
