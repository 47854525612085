import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isUserAuthorized, isUserContentManager } from "../userAuth";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { useAuth0, User } from "@auth0/auth0-react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <div className="nav-container">
      {process.env.REACT_APP_SERVER === "http://localhost:3000" &&
        <div>
          Connected to local Service
        </div>
      }
      <Navbar color="light" light expand="md">
        <div style={{ width: "80%", margin: "0 auto" }}>
          <NavbarBrand className="logo-custom" />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>

            <Nav className="mr-auto" navbar>
              {isAuthenticated && isUserAuthorized(user) && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Locations
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && isUserAuthorized(user) && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/Ads"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                  Ads
                  </NavLink>
                </NavItem>
              )}
 
              {isAuthenticated && isUserAuthorized(user) && !isUserContentManager(user) && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/Feedback"
                    exact
                    activeClassName="router-link-exact-active"
                  >
                    Feedback
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && isUserAuthorized(user) && (
                <NavItem>
                  {/* <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button> */}
                </NavItem>
              )}
              {isAuthenticated && isUserAuthorized(user) && (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row", paddingBottom: 50 }}>
                  <div style={{ textAlign: "center", paddingRight: 10, marginBottom: 10 }}>
                    <h5>{user["https://familyactivies.de/roles"].includes("ContentManager") ? "Content Manager" : user["https://familyactivies.de/roles"][0]}</h5>
                    {user["name"]}
                  </div>

                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret id="profileDropDown">
                      <img
                        src={user.picture}
                        alt="Profile"
                        className="nav-user-profile rounded-circle"
                        width="50"
                      />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>{user.name}</DropdownItem>
                      <DropdownItem
                        tag={RouterNavLink}
                        to="/profile"
                        className="dropdown-profile"
                        activeClassName="router-link-exact-active"
                      >
                        <FontAwesomeIcon icon="user" className="mr-3" /> Profil
                      </DropdownItem>
                      <DropdownItem
                        id="qsLogoutBtn"
                        onClick={() => logoutWithRedirect()}
                      >
                        <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                        out
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </Nav>
            {!isAuthenticated && isUserAuthorized(user) && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && isUserAuthorized(user) && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>

                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="user" className="mr-3" />
                  <RouterNavLink
                    to="/profile"
                    activeClassName="router-link-exact-active"
                  >
                    Profil
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;
