import React, { Fragment } from "react";

import New from "../components/Feedback";

const FeedbackView = () => (
  <Fragment>
    <New />
    <hr />
  </Fragment>
);

export default FeedbackView;

