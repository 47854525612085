import React, { Fragment } from "react";

import New from "../components/New";

const NewView = () => (
  <Fragment>
    <New />
    <hr />
  </Fragment>
);

export default NewView;

