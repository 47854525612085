import React, { useEffect, useState } from 'react';
import "../Styles/Campaign.css"
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdView from './AdView';
import { useAuth0 } from "@auth0/auth0-react";
import "react-datepicker/dist/react-datepicker.css"; // Standard CSS für Datepicker
import { Button, Label } from 'reactstrap';
import CampaignModal from './CampaignModal';
import axios from 'axios';

const CampaignView = ({ campaigns, advertieser, reload }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const baseUrl = process.env.REACT_APP_SERVER
    const {
        user,
        getAccessTokenSilently,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();

    const renderCampaigns = () => {
        const items = []
        for (const campaign of campaigns) {
            items.push(
                <div className='campaignContainer'>
                    <hr />
                    <AdView campaign={campaign} company={advertieser} reload={reload}   ></AdView>
                </div>
            )
        }
        return items
    }

    async function executeAddCampaign(title) {
        const accessToken = await getAccessTokenSilently();

        const url = baseUrl + '/admin/advertieser/' + advertieser._id + '/campaign/'

        const data =
        {
            title: title,
        }
        await axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json; charset=utf-8",
                'Access-Control-Allow-Origin': baseUrl,
                'Authorization': 'Bearer ' + accessToken,
            }

        }).then((res) => {
            if (res.status === 200 || res.status === 201) {
                alert("Campagin Successfull created")
            }
            else {
                console.log("Could not create Company " + res.status + " " + res.error)
            }
        })
    }

    async function handleModalSubmit(title) {
        await executeAddCampaign(title)
        setIsModalOpen(false)
        reload()
    }

    return (
        <div>

            <CampaignModal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }} onSubmit={handleModalSubmit} />
            <div style={{ marginBottom: "20px" }}>
                <Button onClick={() => { setIsModalOpen(true) }} className="action_button">
                    <FontAwesomeIcon icon={faPlus}
                        style={{ width: "20px", height: "20px", marginRight: "10px" }} />
                    <span style={{ fontSize: "20px", fontWeight: "600", marginTop: "-5px" }}>Neu</span>
                </Button>
            </div>
            {renderCampaigns()}
        </div>
    );
};

export default CampaignView;