import React, { useState } from 'react';
import "../Styles/AdvertieserModal.css"

const Modal = ({ isOpen, onClose, onSubmit }) => {
    const [name, setName] = useState('');
    const [owner, setOwner] = useState('');

    if (!isOpen) {
        return null; // Wenn das Modal nicht geöffnet ist, rendere nichts
    }

    const handleSubmit = (e) => {
        onSubmit(name, owner);
    };

    return (
        <div className="adv-modal-overlay">
            <div className="adv-modal ">
                <h2>Enter Details</h2>
                <div className="input-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className='defaultModalInput'
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="owner">Owner:</label>
                    <input
                        type="text"
                        value={owner}
                        onChange={(e) => setOwner(e.target.value)}
                        className="defaultModalInput"
                    />
                </div>
                <div >
                    <button type="submit" className="actionButton" 
                    style={{ width: "100px", marginRight: "20px", fontWeight: 600 }} 
                    onClick={() => {onSubmit(name,owner)}}>Create</button>
                    <button type="button" className="actionButton" 
                    style={{ width: "100px", fontWeight: 600 }} onClick={onClose} >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
