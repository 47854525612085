import React, { useEffect, useRef, useState } from "react";
import { Button, Accordion, AccordionItem, AccordionHeader, AccordionBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import logo from "../assets/logo.png"
import './Styles/Landing.css'
import './Styles/AdsHome.css'
import { isUserAuthorized, isUserContentManager } from "../userAuth";
import { useAuth0 } from "@auth0/auth0-react";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';
import CampaignView from "./AdsComponents/CampaignView";
import AdvertieserModel from "./AdsComponents/AdvertieserModal";
import axios from "axios";

const AdsHome = () => {

  let baseOffset = 0;
  let limit = 40;

  const filterEnum = {
    reported: "Gemeldet",
    user: "Meine Beiträge",
    social: "Social Media",
    none: "Filter Entfernen",
    mostVisited: "🔍 Meist besucht",
    mostClicked: "🖱️ Meist geklickt",
    mostLiked: "❤️ Meist geliked"
  }

  const getFilterKeyByValue = (value) => {
    return Object.entries(filterEnum).find(([key, val]) => val === value)?.[0];
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_SERVER
  const [advertiser, setadvertiser] = useState([]);
  const [offset, setOffset] = useState(baseOffset)
  const [adsComplete, setAdsComplete] = useState(false)
  const [idDeleteList, setIdDeleteList] = useState([])
  const [pageCount, setPageCount] = useState(0);  // Gesamtanzahl der Seiten
  const [advertiserCount, setAdvertiserCount] = useState(0)
  const [activeId, setActiveId] = useState(0)
  const activePage = useRef(0);
  const [activeAd, setActiveAds] = useState()
  const [accessToken, setAccessToken] = useState("");
  const [displayedLocations, setDisplayedLocations] = useState([])
  const [query, setQuery] = useState("");
  const [filterText, setFilterText] = useState("Filter")
  const history = useHistory();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  //#region  Auth0

  const {
    user,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });


  function logoutUser() {
    alert("Sie sind nicht berechtigt diese Seite zu benutzen. Bitte wennden sie sich an einen Adminestrator oder schreiben sie eine E-Mail an tobias.franz@posteo.de um Zugriff zu beantragen.")
    logoutWithRedirect();
  }

  //#endregion

  //#region Use Effects
  useEffect(() => {
    if (isAuthenticated) {
      const getToken = async () => {
        let accessToken = ""
        accessToken = await getAccessTokenSilently();
        setAccessToken(accessToken);
      }
      try {
        getToken();
      }
      catch (e) {
        console.log(e)
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (accessToken != "") {
      getAllAds()
      setAdsComplete(true)
    }
  }, [accessToken])

  useEffect(() => {
    if (accessToken === "")
      return
    getAllAds()
  }, [offset])

  //#endregion

  //#region Server Calls
  async function getAllAds() {
    //const url = baseUrl + "/admin/ads?offset=" + offset + "&" + "limit=" + limit
    const url = baseUrl + "/admin/advertieser"

    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': baseUrl,
        'Authorization': 'Bearer ' + accessToken,
      },
    });
    if (result.status != 200)
      throw "Not Successfull Exception Status: " + result.status;
    const adsObject = await result.json();
    setadvertiser(adsObject.adveriser)
    console.log("result")
    console.log(adsObject.adveriser)
    setAdvertiserCount(adsObject.count)
    setPageCount(Math.ceil(adsObject.count / limit))
  }
  const [render, setRenderer] = useState(0)

  useEffect(() => {
    setRenderer(render + 1)
    console.log(advertiser)
  }, [advertiser])

  const reload = async () => {
    setTimeout(async () => {
      await getAllAds()
    }, 2000);
  }

  function toggleAds(id) {
    activeAd === id ? setActiveAds(undefined) : setActiveAds(id)
  }


  async function handleSearch(query) {
    if (query.trim() === "") {
      await getAllAds();
      setQuery("")
      return
    }
    else
      //await searchAds
      return

  }

  // async function deleteLocations() {
  //   for (let item of idDeleteList) {
  //     await executeDeleteLocation(item.id)
  //   }

  //   reload()
  //   toggelDelete()
  //   uncheckAll()
  // }

  // async function executeDeleteLocation(id) {
  //   const url = baseUrl + '/admin/location/' + id

  //   await axios({
  //     method: 'delete',
  //     url: url,
  //     headers: {
  //       'Accept': 'application/json',
  //       "Content-Type": "application/json; charset=utf-8",
  //       'Access-Control-Allow-Origin': baseUrl,
  //       'Authorization': 'Bearer ' + accessToken,
  //     }

  //   }).then((res) => {
  //     if (res.status === 200 || res.status === 201) {
  //       console.log("delete location successfully")
  //     }
  //     else {
  //       console.log("Could not delete location {} " + res.status + " " + res.error)
  //     }
  //   })
  // }
  //#endregion

  //#region render Helper

  const handlePageClick = (event) => {
    const i = event.selected;
    const offset = i * limit
    setOffset(offset)
    setActiveId(i)
    activePage.current = i;

  };


  const reanderAccordions = () => {
    if (adsComplete) {
      return (
        <div>
          <Accordion flush open={activeAd} toggle={toggleAds}>
            {renderAdvertiser(advertiser)}
          </Accordion>
        </div>)
    }
  }

  const uncheckAll = () => {
    for (const item of idDeleteList) {
      item.target.checked = false;
    }
    setIdDeleteList([])
  }
  const toggelDelete = () => {
    setIsDeleteOpen(!isDeleteOpen)
  }

  const groupByCampagne = (data) => {
    return data.reduce((acc, obj) => {
      const campaign = obj.campaign;

      // Füge das Objekt dem entsprechenden Campagne-Array hinzu (erzeuge das Array, falls es nicht existiert)
      acc[campaign] = acc[campaign] || [];
      acc[campaign].push(obj);

      return acc;  // Akkumulator wird einfach zurückgegeben
    }, {});
  };


  //render Locations with offset
  function renderAdvertiser() {
    if (advertiser && advertiser.length > 0)
      return advertiser.map((advertiserItem, index) => {
        return (
          <AccordionItem key={index}>
            <AccordionHeader targetId={index}>
              <div className="listHeader">
                <div className="listHeaderItem">
                  {advertiserItem.name}
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId={index}   >
              {activeAd === index &&
                <CampaignView campaigns={advertiserItem.campaigns} advertieser={advertiserItem} reload={reload} />
              }
            </AccordionBody>
          </AccordionItem >
        )
      })
  }

  //#endregion
  async function executeCreateCompany(name, owner) {

    const url = baseUrl + '/admin/advertieser/'
    const data =
    {
      name: name,
      sub: owner
    }
    await axios({
      method: 'post',
      url: url,
      data: data,
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json; charset=utf-8",
        'Access-Control-Allow-Origin': baseUrl,
        'Authorization': 'Bearer ' + accessToken,
      }

    }).then((res) => {
      if (res.status === 200 || res.status === 201) {

      }
      else {
        console.log("Could not create Company " + res.status + " " + res.error)
      }
    })
  }

  function handleModalSubmit(name, owner) {
    console.log("handleModalSubmit")
    setIsModalOpen(false)
    executeCreateCompany(name, owner)
  }

  return (
    <div>
      <AdvertieserModel isOpen={isModalOpen} onClose={() => { setIsModalOpen(false) }} onSubmit={handleModalSubmit} />

      <ToastContainer />
      {!isAuthenticated && (
        <div className="text-center my-5 bg-test">
          <img className="mb-3 app-logo" src={logo} alt="React logo" width="400" />

          <h2 className="mb-4" style={{ color: "#00A6B2" }}>Administration Portal</h2>

          <Button
            id="qsLoginBtn"
            color="primary"
            className="btn-margin"
            onClick={() => loginWithRedirect()}
          >
            Login
          </Button>
        </div>
      )
      }
      {isAuthenticated && !isUserAuthorized(user) && (
        <div>
          {logoutUser()}
        </div>
      )
      }
      {isAuthenticated && isUserAuthorized(user) && (
        <div>
          <h1> {advertiserCount} Ads - Seite {activeId + 1}</h1>
          <hr></hr>
          <br></br>
          <div>
            <div className="container_row" style={{ paddingRight: 30 }}>
              <input id="search" placeholder="Suchen" className="defaultInput" onKeyDown={(e) => e.key === 'Enter' && handleSearch(e.target.value)}></input>
            </div>
          </div>

          <div >
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              previousLabel="< prvious"
              onPageChange={handlePageClick}
              pageRangeDisplayed={25}
              pageCount={pageCount}
              containerClassName="pagination"
              renderOnZeroPageCount={null}
              activeClassName="active" />
            < div className="action_menu" >
              <Button onClick={() => { setIsModalOpen(true) }} className="action_button">
                <FontAwesomeIcon icon={faPlus}
                  style={{ width: "20px", height: "20px", marginRight: "10px" }} />
                <span style={{ fontSize: "20px", fontWeight: "600", marginTop: "-5px" }}>Neu</span>
              </Button>
              <div style={{ height: "50px" }} >
                {idDeleteList.length > 0 ? (
                  <div className="container_row" style={{ marginLeft: "20px" }}>
                    <Button className="action_button" style={{ marginRight: "5px" }} onClick={() => { uncheckAll() }} >
                      <FontAwesomeIcon icon={faSquare}
                        style={{ width: "20px", height: "20px", fontWeight: "200", marginRight: "5px" }} />
                      <span style={{ fontSize: "20px", fontWeight: "600", marginTop: "-5px" }}>Abwählen</span>
                    </Button>

                    <Button className="action_button" onClick={() => { toggelDelete() }} >
                      <FontAwesomeIcon icon={faTrashCan} style={{ width: "20px", height: "20px" }} />
                      <span style={{ fontSize: "20px", fontWeight: "600", marginTop: "-5px" }}>Löschen</span>
                    </Button>
                  </div>
                ) :
                  <div style={{ width: "36px", height: "36px" }}>
                  </div>
                }
              </div>
            </div>
            {reanderAccordions(displayedLocations)}
          </div>
        </div>
      )
      }
    </div >
  )
};

export default AdsHome;
